/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-message.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./notification-message.component";
import * as i4 from "./notifications.service";
var styles_NotificationMessageComponent = [i0.styles];
var RenderType_NotificationMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationMessageComponent, data: {} });
export { RenderType_NotificationMessageComponent as RenderType_NotificationMessageComponent };
function View_NotificationMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("https://polygonscan.com/tx/" + _co.notificationsService.transactionHash); _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "notification__wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "error": 0, "success": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "icon fr"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.notificationsService.hideNotification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationMessageComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "notification__wrapper"; var currVal_1 = _ck(_v, 2, 0, (_co.notificationsService.type === "error"), (_co.notificationsService.type === "success")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.notificationsService.transactionHash; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.notificationsService.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.notificationsService.body; _ck(_v, 9, 0, currVal_3); }); }
export function View_NotificationMessageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationMessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationShown; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-message", [], null, null, null, View_NotificationMessageComponent_0, RenderType_NotificationMessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationMessageComponent, [i4.NotificationsService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationMessageComponentNgFactory = i1.ɵccf("app-notification-message", i3.NotificationMessageComponent, View_NotificationMessageComponent_Host_0, {}, {}, []);
export { NotificationMessageComponentNgFactory as NotificationMessageComponentNgFactory };
