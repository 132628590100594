<div class="head-wrap" [style.background-image]="'url('+bannerBgImage+')'" style="height: 100%;">
<div class="top-part top-info mobile-top-part padding-14-per" style="width: fit-content;float: right;">
  <app-account class="hide-box"></app-account>
</div>
<div class="content row top-info top-title padding-14-per" style="float: unset;height: 108px;">
  <div class="left">
    <h2 class="title font-48-blue ">Buy Cover for 
      <img [src]="getCurrentProtocolLogo()" style="margin: 5px 0 0 21px;">
    </h2>
    <p class="des font-16-blue">Individual Plan</p>
  </div>
</div>
</div>
<app-buy *ngIf="key=='buy'" (onGoTo)="goTo($event)"></app-buy>
<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>
<app-notification-message></app-notification-message>
