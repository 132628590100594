var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { TransactionsService } from '../transactions.service';
import { environment } from '../../environments/environment';
var AccountComponent = /** @class */ (function () {
    function AccountComponent(contractService, transactionsService) {
        this.contractService = contractService;
        this.transactionsService = transactionsService;
        this.onRefresh = new EventEmitter();
        this.loading = false;
        this.usdcBalance = "";
        this.abbrAddress = "";
        this.isCorrectNetwork = false;
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
        this.transactionsShown = false;
    }
    AccountComponent.prototype.ngOnInit = function () {
        this.load();
    };
    AccountComponent.prototype.toggleTransactionsShown = function () {
        this.transactionsShown = !this.transactionsShown;
    };
    AccountComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.contractService.init()];
                    case 1:
                        _c.sent();
                        _a = this;
                        return [4 /*yield*/, this.contractService.getNetworkName()];
                    case 2:
                        _a.isCorrectNetwork = (_c.sent()) == environment.networkName;
                        this.onRefresh.emit();
                        if (!this.contractService.address) return [3 /*break*/, 4];
                        this.abbrAddress = this.getAbbr(this.contractService.address);
                        _b = this;
                        return [4 /*yield*/, this.contractService.loadUSDCBalance(this.contractService.address)];
                    case 3:
                        _b.usdcBalance = _c.sent();
                        _c.label = 4;
                    case 4:
                        window['ethereum'].on('accountsChanged', this.listenAccountChange);
                        this.transactionsService.initTransactions();
                        this.transactionsService.currentRefreshSource.subscribe(function () { _this.refreshBalance(); });
                        return [2 /*return*/];
                }
            });
        });
    };
    AccountComponent.prototype.refreshBalance = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.contractService.loadUSDCBalance(this.contractService.address)];
                    case 1:
                        _a.usdcBalance = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AccountComponent.prototype.switch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(environment.networkName == 'matic-mumbai')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.contractService.switchToMaticMumbai()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.contractService.switchToMatic()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7: return [4 /*yield*/, this.load()];
                    case 8:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AccountComponent.prototype.getAbbr = function (line) {
        var len = line.length;
        if (len < 10)
            return len;
        return line.substr(0, 6) + '...' + line.substr(len - 4, len);
    };
    AccountComponent.prototype.connect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var address, networkName, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.contractService.isConnected()) {
                            this.showAlert("Requires MetaMask", "Please install MetaMask Chrome Extension");
                            return [2 /*return*/];
                        }
                        this.loading = true;
                        return [4 /*yield*/, this.contractService.enable()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.contractService.getNetworkName()];
                    case 2:
                        networkName = _b.sent();
                        if (!(networkName != environment.networkName)) return [3 /*break*/, 3];
                        this.contractService.address = '';
                        this.showAlert("Please switch to the correct network", "Currently we are on " + environment.networkName);
                        return [3 /*break*/, 5];
                    case 3:
                        this.abbrAddress = this.getAbbr(address);
                        _a = this;
                        return [4 /*yield*/, this.contractService.loadUSDCBalance(address)];
                    case 4:
                        _a.usdcBalance = _b.sent();
                        this.onRefresh.emit();
                        _b.label = 5;
                    case 5:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AccountComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    AccountComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    AccountComponent.prototype.listenAccountChange = function (account) {
        window.location.reload();
    };
    return AccountComponent;
}());
export { AccountComponent };
