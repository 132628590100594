/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-head.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../settings/settings.component.ngfactory";
import * as i4 from "../settings/settings.component";
import * as i5 from "../account/account.component.ngfactory";
import * as i6 from "../account/account.component";
import * as i7 from "../contract.service";
import * as i8 from "../transactions.service";
import * as i9 from "./mobile-head.component";
var styles_MobileHeadComponent = [i0.styles];
var RenderType_MobileHeadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileHeadComponent, data: {} });
export { RenderType_MobileHeadComponent as RenderType_MobileHeadComponent };
function View_MobileHeadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "icon-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "/assets/images/icon-down-arrow.png"]], null, null, null, null, null))], null, null); }
function View_MobileHeadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "icon-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "/assets/images/icon-up-arrow.png"]], null, null, null, null, null))], null, null); }
function View_MobileHeadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "menu-item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "actived": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu-item"; var currVal_1 = _ck(_v, 2, 0, (_co.key === _v.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.menuTitles[_co.menuItems.indexOf(_v.context.$implicit)]; _ck(_v, 4, 0, currVal_2); }); }
function View_MobileHeadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "drop"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeadComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getRestItems(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MobileHeadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings", [["style", "height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"]], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.closeSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_SettingsComponent_0, i3.RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i4.SettingsComponent, [], null, { onClose: "onClose" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MobileHeadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "mobile-head container main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 28, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "head-row row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "logo fl"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "index.html"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "/assets/images/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "potfolio-btn fr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "button"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPortfolio() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["My Reward"])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "crypto-fund-row row"], ["style", "display: flex;flex-flow: row-reverse;gap: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-account", [], null, null, null, i5.View_AccountComponent_0, i5.RenderType_AccountComponent)), i1.ɵdid(11, 114688, null, 0, i6.AccountComponent, [i7.ContractService, i8.TransactionsService], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "config-btn"], ["style", "border: none;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["src", "/assets/images/ledger.svg"], ["style", "width: 30px;\n        height: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "row mobile-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "menu-item actived"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeMenuStatus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeadComponent_1)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeadComponent_2)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeadComponent_3)), i1.ɵdid(23, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["class", "row"], ["style", "display: none;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "p", [["class", "row total-asset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "strong", [["class", "title sim-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total asset under coverage"])), (_l()(), i1.ɵeld(28, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 0, "span", [["class", "number"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileHeadComponent_5)), i1.ɵdid(31, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 11, 0); var currVal_1 = _co.menuHidden; _ck(_v, 19, 0, currVal_1); var currVal_2 = !_co.menuHidden; _ck(_v, 21, 0, currVal_2); var currVal_3 = !_co.menuHidden; _ck(_v, 23, 0, currVal_3); var currVal_4 = _co.willShowSettings; _ck(_v, 31, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuTitles[_co.menuItems.indexOf(_co.key)]; _ck(_v, 17, 0, currVal_0); }); }
export function View_MobileHeadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mobile-head", [], null, null, null, View_MobileHeadComponent_0, RenderType_MobileHeadComponent)), i1.ɵdid(1, 114688, null, 0, i9.MobileHeadComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileHeadComponentNgFactory = i1.ɵccf("app-mobile-head", i9.MobileHeadComponent, View_MobileHeadComponent_Host_0, { key: "key" }, { onGoTo: "onGoTo" }, []);
export { MobileHeadComponentNgFactory as MobileHeadComponentNgFactory };
