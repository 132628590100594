/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./transactions-window.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./transactions-window.component";
import * as i4 from "../transactions.service";
var styles_TransactionsWindowComponent = [i0.styles];
var RenderType_TransactionsWindowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TransactionsWindowComponent, data: {} });
export { RenderType_TransactionsWindowComponent as RenderType_TransactionsWindowComponent };
function View_TransactionsWindowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View"]))], null, function (_ck, _v) { var currVal_0 = ("https://polygonscan.com/tx/" + _v.parent.context.$implicit["transactionHash"]); _ck(_v, 0, 0, currVal_0); }); }
function View_TransactionsWindowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "display: flex; align-items: center; justify-content: space-between; width: 70%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "success": 0, "error": 1, "pending": 2 }), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsWindowComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit["action"] === "SUCCESS"), (_v.context.$implicit["action"] === "ERROR"), (_v.context.$implicit["action"] === "START")); _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit["transactionHash"]; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit["type"] === "STAKING_CLAIM") ? "Staking Claim" : _v.context.$implicit["type"]); _ck(_v, 4, 0, currVal_1); }); }
function View_TransactionsWindowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No transactions yet"]))], null, null); }
export function View_TransactionsWindowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "popup-bg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "popup-window"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "popup-title title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Transactions for ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "icon fr"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "deposit-row"], ["style", "margin-bottom: -5px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsWindowComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsWindowComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.transactionService.transactions; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.transactionService.transactions.length; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address; _ck(_v, 3, 0, currVal_0); }); }
export function View_TransactionsWindowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-transactions-window", [], null, null, null, View_TransactionsWindowComponent_0, RenderType_TransactionsWindowComponent)), i1.ɵdid(1, 49152, null, 0, i3.TransactionsWindowComponent, [i4.TransactionsService], null, null)], null, null); }
var TransactionsWindowComponentNgFactory = i1.ɵccf("app-transactions-window", i3.TransactionsWindowComponent, View_TransactionsWindowComponent_Host_0, { address: "address" }, { onClose: "onClose" }, []);
export { TransactionsWindowComponentNgFactory as TransactionsWindowComponentNgFactory };
