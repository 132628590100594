var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TransactionsService } from './transactions.service';
import { NotificationsService } from './notification-message/notifications.service';
import * as transactionsDescriptions from './transactions.descriptions';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./transactions.service";
import * as i2 from "./notification-message/notifications.service";
var ContractService = /** @class */ (function () {
    function ContractService(transactionsService, notifications) {
        this.transactionsService = transactionsService;
        this.notifications = notifications;
        this.address = '';
        this.usdcBalance = '';
    }
    ContractService.prototype.getFixedStr = function (value, precision) {
        return (Math.max(0, value - Math.pow(0.1, precision) / 2)).toFixed(precision).toString();
    };
    ContractService.prototype._wait = function (duration) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    resolve();
                    return [2 /*return*/];
                });
            }); }, duration);
        });
    };
    ContractService.prototype.isConnected = function () {
        return window['ethereum'] && window['ethereum'].isConnected();
    };
    ContractService.prototype._getWeb3 = function () {
        return this.web3m;
    };
    ContractService.prototype.waitForConnection = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!1) return [3 /*break*/, 4];
                        if (!this.address) return [3 /*break*/, 1];
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, this._wait(500)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 0];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.enable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, e_1, provider;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, window['ethereum'].send('eth_requestAccounts')];
                    case 1:
                        res = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        return [2 /*return*/, ""];
                    case 3:
                        provider = environment.web3EndPoint ?
                            new window['Web3'].providers.HttpProvider(environment.web3EndPoint) :
                            window['ethereum'];
                        this.web3 = new window['Web3'](provider);
                        this.web3m = new window['Web3'](window['ethereum']);
                        this.address = res && res.result ? res.result[0] : "";
                        return [2 /*return*/, this.address];
                }
            });
        });
    };
    ContractService.prototype.balanceOf = function (tokenAddress, address, decimal, precision) {
        if (precision === void 0) { precision = 2; }
        return __awaiter(this, void 0, void 0, function () {
            var token, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = new this.web3.eth.Contract(environment.erc20Abi, tokenAddress);
                        return [4 /*yield*/, token.methods.balanceOf(address).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedStr((+value) / (Math.pow(10, decimal)), precision)];
                }
            });
        });
    };
    ContractService.prototype.getAllowance = function (tokenAddress, address, spender, decimal, precision) {
        if (precision === void 0) { precision = 2; }
        return __awaiter(this, void 0, void 0, function () {
            var token, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = new this.web3.eth.Contract(environment.erc20Abi, tokenAddress);
                        return [4 /*yield*/, token.methods.allowance(address, spender).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedStr((+value) / (Math.pow(10, decimal)), precision)];
                }
            });
        });
    };
    ContractService.prototype.loadUSDCBalance = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.balanceOf(environment.usdcAddress, address, environment.usdcDecimals)];
                    case 1:
                        _a.usdcBalance = _b.sent();
                        return [2 /*return*/, this.usdcBalance];
                }
            });
        });
    };
    ContractService.prototype.getUserInfo = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new this.web3.eth.Contract(environment.retailHelperAbi, environment.retailHelperAddress);
                        return [4 /*yield*/, retailHelper.methods.userInfoMap(environment.assetIndex, address).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getAssetInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new this.web3.eth.Contract(environment.retailHelperAbi, environment.retailHelperAddress);
                        return [4 /*yield*/, retailHelper.methods.assetInfoMap(environment.assetIndex).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getSubscriptionByUser = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new this.web3.eth.Contract(environment.retailHelperAbi, environment.retailHelperAddress);
                        return [4 /*yield*/, retailHelper.methods.subscriptionByUser(environment.assetIndex, address).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getPremiumRate = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new this.web3.eth.Contract(environment.retailHelperAbi, environment.retailHelperAddress);
                        return [4 /*yield*/, retailHelper.methods.getPremiumRate(environment.assetIndex, address).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getEffectiveCapacity = function () {
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new this.web3.eth.Contract(environment.retailHelperAbi, environment.retailHelperAddress);
                        return [4 /*yield*/, retailHelper.methods.getEffectiveCapacity(environment.assetIndex).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype._send = function (sendHandler, transaction) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            sendHandler
                .once('transactionHash', function (transactionHash) {
                _this.transactionsService.addTransaction({
                    action: 'START',
                    type: transaction.type,
                    transactionHash: transactionHash
                });
            })
                .once('confirmation', function (confirmationNumber, receipt) {
                _this.transactionsService.updateTransaction({
                    action: 'SUCCESS',
                    transactionHash: receipt.transactionHash,
                    type: transaction.type
                });
                _this.notifications.showNotification({
                    title: transaction.type,
                    body: "Success. Thanks for " + transaction.type,
                    transactionHash: receipt.transactionHash,
                    type: 'success'
                });
                resolve(receipt);
            })
                .once('error', function (error) {
                _this.transactionsService.updateTransaction({
                    action: 'ERROR',
                    transactionHash: error.receipt ? error.receipt.transactionHash : null,
                    type: transaction.type,
                });
                _this.notifications.showNotification({
                    title: transaction.type,
                    body: error.code === 4001 ? 'Transaction rejected' : 'Transaction fails',
                    transactionHash: error['receipt'] ? error['receipt']['transactionHash'] : null,
                    type: 'error'
                });
                reject(error);
            });
        });
    };
    ContractService.prototype._decToHex = function (x, decimal) {
        if (x == 0)
            return '0x0';
        var str = x;
        for (var index = 0; index < decimal; index++) {
            str += "0";
        }
        var pos = str.indexOf(".");
        if (pos != -1) {
            str = str.substr(0, pos) + str.substr(pos + 1, decimal);
        }
        var dec = str.toString().split(''), sum = [], hex = [], i, s;
        while (dec.length) {
            s = 1 * parseInt(dec.shift());
            for (i = 0; s || i < sum.length; i++) {
                s += (sum[i] || 0) * 10;
                sum[i] = s % 16;
                s = (s - sum[i]) / 16;
            }
        }
        while (sum.length) {
            hex.push(sum.pop().toString(16));
        }
        return '0x' + hex.join('');
    };
    ContractService.prototype.deposit = function (amount, isBase, decimals) {
        if (isBase === void 0) { isBase = true; }
        if (decimals === void 0) { decimals = environment.usdcDecimals; }
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper, res, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new (this._getWeb3().eth.Contract)(environment.retailHelperAbi, environment.retailHelperAddress);
                        if (!isBase) return [3 /*break*/, 2];
                        res = retailHelper.methods.depositBase(environment.assetIndex, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.depositTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        res = retailHelper.methods.depositAsset(environment.assetIndex, this._decToHex(amount, decimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.depositTransaction)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.withdraw = function (amount, isBase, decimals) {
        if (isBase === void 0) { isBase = true; }
        if (decimals === void 0) { decimals = environment.usdcDecimals; }
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper, res, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new (this._getWeb3().eth.Contract)(environment.retailHelperAbi, environment.retailHelperAddress);
                        if (!isBase) return [3 /*break*/, 2];
                        res = retailHelper.methods.withdrawBase(environment.assetIndex, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.withdrawTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        res = retailHelper.methods.withdrawAsset(environment.assetIndex, this._decToHex(amount, decimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.withdrawTransaction)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.adjustSubscription = function (amount, isBase) {
        if (isBase === void 0) { isBase = true; }
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper, res, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new (this._getWeb3().eth.Contract)(environment.retailHelperAbi, environment.retailHelperAddress);
                        if (!isBase) return [3 /*break*/, 2];
                        res = retailHelper.methods.adjustSubscriptionBase(environment.assetIndex, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.adjustSubscriptionTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        res = retailHelper.methods.adjustSubscriptionAsset(environment.assetIndex, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.adjustSubscriptionTransaction)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getRetailUserMap = function (index) {
        return __awaiter(this, void 0, void 0, function () {
            var retailMapping;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailMapping = new (this._getWeb3().eth.Contract)(environment.retailMappingAbi, environment.retailMappingAddress);
                        return [4 /*yield*/, retailMapping.methods.userMap(this.address, index).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.updateMapping = function (addressArray) {
        return __awaiter(this, void 0, void 0, function () {
            var retailMapping, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailMapping = new (this._getWeb3().eth.Contract)(environment.retailMappingAbi, environment.retailMappingAddress);
                        res = retailMapping.methods.updateMapping(addressArray).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.updateMappingTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getNetworkName = function () {
        return __awaiter(this, void 0, void 0, function () {
            var id;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._wait(1000)];
                    case 1:
                        _a.sent();
                        if (!window['ethereum'])
                            return [2 /*return*/, 'unknown'];
                        id = +window['ethereum'].chainId;
                        switch (id) {
                            case 1:
                                return [2 /*return*/, 'main'];
                            case 2:
                                return [2 /*return*/, 'morden'];
                            case 3:
                                return [2 /*return*/, 'ropsten'];
                            case 4:
                                return [2 /*return*/, 'rinkeby'];
                            case 5:
                                return [2 /*return*/, 'goerli'];
                            case 42:
                                return [2 /*return*/, 'kovan'];
                            case 56:
                                return [2 /*return*/, 'bsc'];
                            case 137:
                                return [2 /*return*/, 'matic'];
                            case 80001:
                                return [2 /*return*/, 'matic-mumbai'];
                            default:
                                return [2 /*return*/, 'unknown'];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.switchToMatic = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, window['ethereum'].request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x89',
                                    chainName: 'Matic Network',
                                    nativeCurrency: {
                                        name: 'Matic',
                                        symbol: 'Matic',
                                        decimals: 18,
                                    },
                                    rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
                                    blockExplorerUrls: ['https://polygonscan.com']
                                }
                            ]
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.switchToMaticMumbai = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, window['ethereum'].request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x13881',
                                    chainName: 'Matic Mumbai',
                                    nativeCurrency: {
                                        name: 'Matic',
                                        symbol: 'Matic',
                                        decimals: 18,
                                    },
                                    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
                                    blockExplorerUrls: ['https://mumbai.polygonscan.com']
                                }
                            ]
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.approve = function (tokenAddress, spender, accountAddress) {
        if (accountAddress === void 0) { accountAddress = this.address; }
        return __awaiter(this, void 0, void 0, function () {
            var token, amount, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = new (this.web3m.eth.Contract)(environment.erc20Abi, tokenAddress);
                        if (tokenAddress.toLowerCase() == '0x34c1b299a74588d6abdc1b85a53345a48428a521') {
                            // HACK: For EZ, which is 96 bits.
                            amount = '0xffffffffffffffffffffffff';
                        }
                        else {
                            amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
                        }
                        res = token.methods.approve(spender, amount).send({ from: accountAddress });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.approveTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isUnlocked;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isUnlocked()];
                    case 1:
                        isUnlocked = _a.sent();
                        if (!isUnlocked) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.enable()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.chainChanged();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.isUnlocked = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = window['ethereum'];
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, window['ethereum']['_metamask'].isUnlocked()];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2: return [2 /*return*/, _a];
                }
            });
        });
    };
    ContractService.prototype.chainChanged = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                window['ethereum'].on('chainChanged', function () {
                    window.location.reload();
                });
                return [2 /*return*/];
            });
        });
    };
    ContractService.ngInjectableDef = i0.defineInjectable({ factory: function ContractService_Factory() { return new ContractService(i0.inject(i1.TransactionsService), i0.inject(i2.NotificationsService)); }, token: ContractService, providedIn: "root" });
    return ContractService;
}());
export { ContractService };
