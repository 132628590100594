/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./settings.component";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "popup-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "popup-window"], ["style", "text-align: center; background-color: #dfffff; max-width: 380px; height: 200px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "popup-title title"], ["style", "font-size: 18px; padding-left: 32px; background-color: white; border-top-left-radius: 16px; border-top-right-radius: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Settings "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "icon fr"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["style", "padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["style", "width: 100%; display: inline-block; margin-top: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "info-line"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Turn off Biconomy if you use a ledger, MATIC would be required for small transaction fees "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [["class", "title"], ["style", "float: left; font-size: 14px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Use Biconomy"])), (_l()(), i1.ɵeld(12, 0, null, null, 6, "p", [["class", "number"], ["style", "float: right; font-size: 14px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "span", [["class", "switch"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleBiconomy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(15, { "active": 0 }), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["class", "switch-toggle"]], null, null, null, null, null)), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "switch"; var currVal_1 = _ck(_v, 15, 0, _co.willUseBiconomy); _ck(_v, 14, 0, currVal_0, currVal_1); var currVal_2 = "switch-toggle"; var currVal_3 = _ck(_v, 18, 0, _co.willUseBiconomy); _ck(_v, 17, 0, currVal_2, currVal_3); }, null); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.SettingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsComponentNgFactory = i1.ɵccf("app-settings", i3.SettingsComponent, View_SettingsComponent_Host_0, {}, { onClose: "onClose" }, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
