var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
var BuySubscribeComponent = /** @class */ (function () {
    function BuySubscribeComponent(contractService, apiService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.onClose = new EventEmitter();
        this.onRefresh = new EventEmitter();
        this.amount = "";
        this.predepositBalance = "";
        this.futureSubscription = "";
        this.futureSubscriptionNumber = 0;
        this.futurePremium = "";
        this.assetIndex = environment.assetIndex;
        this.assetSymbol = environment.assetSymbol;
        this.loading = false;
    }
    BuySubscribeComponent.prototype.ngOnInit = function () {
        this.load();
    };
    BuySubscribeComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userInfo, userSubscription, premiumRate, all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getUserInfo(this.contractService.address)];
                                        case 1:
                                            userInfo = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getSubscriptionByUser(this.contractService.address)];
                                        case 1:
                                            userSubscription = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getPremiumRate(this.contractService.address)];
                                        case 1:
                                            premiumRate = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        this.loading = true;
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        if (this.tabIndex == 0) {
                            this.predepositBalance = this.getTokenBalance(userInfo[0], environment.usdcDecimals);
                            this.futureSubscription = this.getTokenBalance(userSubscription[2], environment.usdcDecimals);
                            this.futureSubscriptionNumber = (+userSubscription[2]) / (Math.pow(10, environment.usdcDecimals));
                            this.futurePremium = this.getTokenBalance(((+userSubscription[2]) * premiumRate / 1e6).toFixed(2), environment.usdcDecimals);
                        }
                        else {
                            this.predepositBalance = this.getTokenBalance(userInfo[1], environment.assetDecimals);
                            this.futureSubscription = this.getTokenBalance(userSubscription[3], environment.usdcDecimals);
                            this.futureSubscriptionNumber = (+userSubscription[3]) / (Math.pow(10, environment.usdcDecimals));
                            this.futurePremium = this.getTokenBalance(((+userSubscription[3]) * premiumRate / 1e6).toFixed(2), environment.usdcDecimals);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BuySubscribeComponent.prototype.max = function () {
        this.amount = this.predepositBalance;
    };
    BuySubscribeComponent.prototype.isNumber = function (x) {
        return !isNaN(parseFloat(x));
    };
    BuySubscribeComponent.prototype.getNumber = function (x) {
        return parseFloat(x);
    };
    BuySubscribeComponent.prototype.getTokenBalance = function (value, decimals) {
        return ((+value) / (Math.pow(10, decimals))).toFixed(2);
    };
    BuySubscribeComponent.prototype.formatTokenBalance = function (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    BuySubscribeComponent.prototype.adjust = function () {
        return __awaiter(this, void 0, void 0, function () {
            var amount, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        amount = +this.getNumber(this.amount).toFixed(2);
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.adjustSubscription(amount, this.tabIndex == 0)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.load()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.loading = false;
                        this.onRefresh.emit();
                        return [2 /*return*/];
                }
            });
        });
    };
    BuySubscribeComponent.prototype.willDisableButton = function () {
        var buttonDisabled = (this.loading || isNaN(+this.amount));
        return buttonDisabled;
    };
    BuySubscribeComponent.prototype.close = function () {
        this.onClose.emit();
    };
    return BuySubscribeComponent;
}());
export { BuySubscribeComponent };
