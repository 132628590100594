import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';

import { updateMappingTransaction} from '../transactions.descriptions';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  isEditMode: boolean = false;
  asset: any = {};
  predepositBalance: string = "";
  premiumRate: string = "";
  effectiveCapacity: string = "";
  myCurrentCoverage: string = "";
  myFutureCoverage: string = "";
  myCurrentPremium: string = "";
  myFuturePremium: string = "";

  weeksBeCovered = 0;
  isCovered = false;

  records = [];

  address0 = "";
  address1 = "";
  address2 = "";
  address3 = "";
  address4 = "";
  address5 = "";
  address6 = "";
  address7 = "";
  address8 = "";
  address9 = "";

  loading = false;
  saving = false;

  tabIndex = 0;

  assetIndex = environment.assetIndex;
  assetSymbol = environment.assetSymbol;
  assetTokenAddress = environment.assetTokenAddress;

  pageLimit = 20;
  pageOffset = 0;

  willShowDeposit: boolean = false;
  willShowWithdraw: boolean = false;
  willShowSubscribe: boolean = false;

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;
  paymentAccountAddr: string = '';

  constructor(private contractService: ContractService,
      private apiService: ApiService,
      private transactionsService: TransactionsService) {
  }

  ngOnInit() {
    this.load();
  }

  async load() {
    await this.contractService.waitForConnection();

    this.paymentAccountAddr = this.contractService.address;

    let userInfo;
    let assetInfo;
    let userSubscription;

    const all = [(async () => {
      userInfo = await this.contractService.getUserInfo(this.contractService.address);
    })(), (async () => {
      assetInfo = await this.contractService.getAssetInfo();
    })(), (async () => {
      userSubscription = await this.contractService.getSubscriptionByUser(this.contractService.address);
    })(), (async () => {
      this.premiumRate = await this.contractService.getPremiumRate(this.contractService.address);
    })(), (async () => {
      this.effectiveCapacity = this.getTokenBalance(
          (await this.contractService.getEffectiveCapacity()), environment.usdcDecimals);
    })(), (async () => {
      await this.loadRecords();
    })(), (async () => {
      await this.loadAddresses();
    })()];

    this.loading = true;
    await Promise.all(all);
    this.loading = false;

    if (this.tabIndex == 0) {
      this.predepositBalance = this.getTokenBalance(userInfo[0], environment.usdcDecimals);
      this.myCurrentCoverage = this.getTokenBalance(userSubscription[0], environment.usdcDecimals);
      this.myFutureCoverage = this.getTokenBalance(userSubscription[2], environment.usdcDecimals);
      this.myCurrentPremium = this.getTokenBalance(userInfo[2], environment.usdcDecimals);
      this.myFuturePremium = this.getTokenBalance(
          ((+userSubscription[2]) * (+this.premiumRate) / 1e6).toFixed(2), environment.usdcDecimals);
      this.weeksBeCovered = Math.floor((+userInfo[0]) / (+userInfo[2]));
      this.isCovered = (+userInfo[2]) > 0 && this.weeksBeCovered > 0;
    } else {
      this.predepositBalance = this.getTokenBalance(userInfo[1], environment.assetDecimals, environment.assetPrecision);
      this.myCurrentCoverage = this.getTokenBalance(userSubscription[1], environment.usdcDecimals);
      this.myFutureCoverage = this.getTokenBalance(userSubscription[3], environment.usdcDecimals);
      this.myCurrentPremium = this.getTokenBalance(
          (+userInfo[3]) * (+assetInfo.tokenPrice / 1e18), environment.usdcDecimals);
      this.myFuturePremium = this.getTokenBalance(
          ((+userSubscription[3]) * (+this.premiumRate) / 1e6).toFixed(2), environment.usdcDecimals);
      this.weeksBeCovered = Math.floor((+userInfo[1]) / (+userInfo[3]));
      this.isCovered = (+userInfo[3]) > 0 && this.weeksBeCovered > 0;
    }
  }

  async loadRecords() {
    const records = await this.apiService.getRetailHistory(
        this.assetIndex, this.contractService.address, this.pageLimit, this.pageOffset);
    this.records = records.map(r => {
      return {
        blockTime: r.blockTime,
        futureBase: this.getTokenBalance(r.futureBase, environment.usdcDecimals),
        currentBase: this.getTokenBalance(r.currentBase, environment.usdcDecimals),
        premiumBase: this.getTokenBalance(r.premiumBase, environment.usdcDecimals),
        futureAsset: this.getTokenBalance(r.futureAsset, environment.usdcDecimals),
        currentAsset: this.getTokenBalance(r.currentAsset, environment.usdcDecimals),
        premiumAsset: this.getTokenBalance(r.premiumAsset, environment.assetDecimals, environment.assetPrecision)
      }
    });
  }

  async loadAddresses() {
    const result = [];
    const all = [];
    for (let i = 0; i < 10; ++i) {
      all.push((async (i) => {
        try {
          result[i] = await this.contractService.getRetailUserMap(i);
        } catch(e) {
        }
      })(i));
    }

    await Promise.all(all);
    for (let i = 0; i < result.length; ++i) {
      if (i == 0) {
        this.address0 = result[0];
      } else if (i == 1) {
        this.address1 = result[1];
      } else if (i == 2) {
        this.address2 = result[2];
      } else if (i == 3) {
        this.address3 = result[3];
      } else if (i == 4) {
        this.address4 = result[4];
      } else if (i == 5) {
        this.address5 = result[5];
      } else if (i == 6) {
        this.address6 = result[6];
      } else if (i == 7) {
        this.address7 = result[7];
      } else if (i == 8) {
        this.address8 = result[8];
      } else if (i == 9) {
        this.address9 = result[9];
      }
    }
  }

  refresh() {
    this.load();
  }

  getTokenBalance(value, decimals, precision=2) {
    return ((+value) / (10 ** decimals)).toFixed(precision);
  }

  formatTokenBalance(value) {
    const arr = value.toString().split(".");
    if (arr[1]) {
      return [arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","), arr[1]].join(".");
    } else {
      return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  formatDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return  (date.getMonth() + 1) + " / " + date.getDate() + " / " + date.getFullYear();
  }

  formatRate(value) {
    if (isNaN(value)) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  getNumber(value) {
    return parseFloat(value);
  }

  showLockedDesc(type: string) {
    if (type === 'PreDepositBalance') {
      this.showAlert("My Predeposit Balance", "Weekly coverage cost will be automatically deducted from the pre-deposit balance to save user’s gas. Please keep enough balance to maintain your coverage plan.");
    } else if (type === 'EstimateCoveragePeriod') {
      this.showAlert("Estimate Coverage Period", "Estimate coverage period is calculated based on your coverage amount and pre-deposit balance. Keep it for few weeks to have a peace of mind.");
    }
  }

  toggleShowFloatPopup ($event, status) {
    const floatItem = $event.target.parentNode.parentNode.querySelector('.float-item');
    floatItem.style.visibility = status;
  }

  showDeposit() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowDeposit = true;
  }

  closeDeposit() {
    this.willShowDeposit = false;
  }

  showWithdraw() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowWithdraw = true;
  }

  toggleLocked () {
    this.isEditMode = true;
  }

  isValidAddress(address) {
    return address && address.length == 42 && address[0] == "0" && address[1] == "x";
  }

  async saveEditResult() {
    const array = [];

    if (this.isValidAddress(this.address0)) {
      array.push(this.address0);
    }
    if (this.isValidAddress(this.address1)) {
      array.push(this.address1);
    }
    if (this.isValidAddress(this.address2)) {
      array.push(this.address2);
    }
    if (this.isValidAddress(this.address3)) {
      array.push(this.address3);
    }
    if (this.isValidAddress(this.address4)) {
      array.push(this.address4);
    }
    if (this.isValidAddress(this.address5)) {
      array.push(this.address5);
    }
    if (this.isValidAddress(this.address6)) {
      array.push(this.address6);
    }
    if (this.isValidAddress(this.address7)) {
      array.push(this.address7);
    }
    if (this.isValidAddress(this.address8)) {
      array.push(this.address8);
    }
    if (this.isValidAddress(this.address9)) {
      array.push(this.address9);
    }

    this.saving = true;
 
    try {
      this.transactionsService.updateProcessingTransaction(updateMappingTransaction.type, true);
      await this.contractService.updateMapping(array);
      await this.loadAddresses();
      this.isEditMode = false;
    } catch(e) {
    }

    this.transactionsService.updateProcessingTransaction(updateMappingTransaction.type, false);
    this.saving = false;
  }

  closeWithdraw() {
    this.willShowWithdraw = false;
  }

  updateProtectList() {}

  showSubscribe(assetIndex: number) {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowSubscribe = true;
    this.assetSymbol = this.asset.assetSymbol;
  }

  closeSubscribe() {
    this.willShowSubscribe = false;
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }

  showUSDCTab() {
    this.tabIndex = 0;
    this.load();
  }

  showAssetTab(idx) {
    this.tabIndex = idx;
    this.load();
  }

  goFirst() {
    this.pageOffset = 0;
    this.loadRecords();
  }

  goPrev() {
    this.pageOffset -= this.pageLimit;
    this.loadRecords();
  }

  goNext() {
    this.pageOffset -= this.pageLimit;
    this.loadRecords();
  }

  hasFirst() {
    return this.pageOffset > 0;
  }

  hasPrev() {
    return this.pageOffset >= this.pageLimit;
  }

  hasNext() {
    return this.records.length >= this.pageLimit;
  }
}
