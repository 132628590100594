/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-message.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alert-message.component";
var styles_AlertMessageComponent = [i0.styles];
var RenderType_AlertMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertMessageComponent, data: {} });
export { RenderType_AlertMessageComponent as RenderType_AlertMessageComponent };
export function View_AlertMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "popup-bg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "popup-window"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "popup-title title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "icon fr"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "deposit-row"], ["style", "margin-bottom: -5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.body; _ck(_v, 8, 0, currVal_1); }); }
export function View_AlertMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-message", [], null, null, null, View_AlertMessageComponent_0, RenderType_AlertMessageComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlertMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertMessageComponentNgFactory = i1.ɵccf("app-alert-message", i2.AlertMessageComponent, View_AlertMessageComponent_Host_0, { title: "title", body: "body" }, { onClose: "onClose" }, []);
export { AlertMessageComponentNgFactory as AlertMessageComponentNgFactory };
