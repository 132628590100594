var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { NotificationsService } from './notification-message/notifications.service';
import * as transactionsDescriptions from './transactions.descriptions';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./notification-message/notifications.service";
var TransactionsService = /** @class */ (function () {
    function TransactionsService(notifications) {
        this.notifications = notifications;
        this.transactions = [];
        this.pendingTransactions = [];
        this.isTransactionsProcessing = false;
        this.onRefresh = null;
        this.notionSource = new BehaviorSubject('');
        this.currentRefreshSource = this.notionSource.asObservable();
        this.processingTransactions = {};
    }
    TransactionsService.prototype.notionRefreshUsdcBalance = function (isRefresh) {
        this.notionSource.next(isRefresh);
    };
    TransactionsService.prototype.setProcessingTransactions = function () {
        var transactionsTypes = Object.values(transactionsDescriptions).map(function (item) { return item.type; });
        for (var i = 0; i < transactionsTypes.length; i++) {
            this.processingTransactions[transactionsTypes[i]] = false;
        }
    };
    TransactionsService.prototype.updateProcessingTransaction = function (transactionType, isTransactionProcessing) {
        this.processingTransactions[transactionType] = isTransactionProcessing;
        this.updateIsTransactionsProcessing();
    };
    TransactionsService.prototype.updateIsTransactionsProcessing = function () {
        this.isTransactionsProcessing = Object.values(this.processingTransactions).some(function (item) { return item; })
            || !!this.pendingTransactions.length;
    };
    TransactionsService.prototype.addTransaction = function (transaction) {
        var transactions = JSON.parse(window.localStorage.getItem('transactions'));
        if (transactions && transactions.length === 6) {
            transactions.shift();
        }
        window.localStorage.setItem('transactions', JSON.stringify(transactions.concat([transaction])));
        this.transactions = this.getTransactions();
    };
    TransactionsService.prototype.updateTransaction = function (transaction) {
        var transactions = JSON.parse(window.localStorage.getItem('transactions'));
        var existingTransactionIndex = transactions.findIndex(function (tr) { return tr.transactionHash === transaction.transactionHash; });
        if (existingTransactionIndex === -1) {
            return;
        }
        transactions.splice(existingTransactionIndex, 1, transaction);
        window.localStorage.setItem('transactions', JSON.stringify(transactions.slice()));
        this.transactions = this.getTransactions();
    };
    TransactionsService.prototype.getTransactions = function () {
        var transactions = window.localStorage.getItem('transactions');
        if (!transactions) {
            window.localStorage.setItem('transactions', JSON.stringify([]));
            return [];
        }
        return JSON.parse(transactions).reverse();
    };
    TransactionsService.prototype.handlePendingTransactions = function () {
        var _this = this;
        var w3 = new window['Web3'](window['Web3'].givenProvider);
        this.pendingTransactions = this.transactions.filter(function (transaction) { return transaction.action === 'START'; });
        if (this.pendingTransactions.length) {
            // TODO: add local spinners after page refresh
            this.isTransactionsProcessing = true;
        }
        this.pendingTransactions.forEach(function (transaction, index) {
            w3.eth.getTransactionReceipt(transaction.transactionHash)
                .then(function (receipt) {
                _this.updateTransaction(__assign({}, transaction, { action: 'SUCCESS' }));
                _this.notifications.showNotification({
                    title: transaction['type'],
                    body: "Success. Thanks for " + transaction['type'],
                    transactionHash: transaction.transactionHash,
                    type: 'success',
                });
            })
                .catch(function (e) {
                _this.updateTransaction(__assign({}, transaction, { action: 'ERROR' }));
                _this.notifications.showNotification({
                    title: transaction['type'],
                    body: 'Some error occurred',
                    transactionHash: transaction.transactionHash,
                    type: 'error',
                });
            })
                .finally(function () {
                _this.pendingTransactions.splice(index, 1);
                if (!_this.pendingTransactions.length) {
                    _this.updateIsTransactionsProcessing();
                }
            });
        });
    };
    TransactionsService.prototype.initTransactions = function () {
        this.transactions = this.getTransactions();
        this.handlePendingTransactions();
        this.setProcessingTransactions();
    };
    TransactionsService.ngInjectableDef = i0.defineInjectable({ factory: function TransactionsService_Factory() { return new TransactionsService(i0.inject(i1.NotificationsService)); }, token: TransactionsService, providedIn: "root" });
    return TransactionsService;
}());
export { TransactionsService };
