import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var NotificationsService = /** @class */ (function () {
    function NotificationsService() {
        this.NOTIFICATION_DURATION = 5000;
        this.shownSource = new BehaviorSubject(false);
        this.notificationShown = this.shownSource.asObservable();
    }
    NotificationsService.prototype.hideNotification = function () {
        this.shownSource.next(false);
    };
    NotificationsService.prototype.showNotification = function (_a) {
        var _this = this;
        var title = _a.title, body = _a.body, transactionHash = _a.transactionHash, type = _a.type;
        this.type = type, this.title = title, this.body = body, this.transactionHash = transactionHash;
        this.shownSource.next(true);
        setTimeout(function () {
            _this.hideNotification();
            _this.title = '';
            _this.body = '';
            _this.transactionHash = '';
            _this.type = '';
        }, this.NOTIFICATION_DURATION);
    };
    NotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
