var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { updateMappingTransaction } from '../transactions.descriptions';
import { environment } from '../../environments/environment';
var BuyComponent = /** @class */ (function () {
    function BuyComponent(contractService, apiService, transactionsService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.transactionsService = transactionsService;
        this.onGoTo = new EventEmitter();
        this.isEditMode = false;
        this.asset = {};
        this.predepositBalance = "";
        this.premiumRate = "";
        this.effectiveCapacity = "";
        this.myCurrentCoverage = "";
        this.myFutureCoverage = "";
        this.myCurrentPremium = "";
        this.myFuturePremium = "";
        this.weeksBeCovered = 0;
        this.isCovered = false;
        this.records = [];
        this.address0 = "";
        this.address1 = "";
        this.address2 = "";
        this.address3 = "";
        this.address4 = "";
        this.address5 = "";
        this.address6 = "";
        this.address7 = "";
        this.address8 = "";
        this.address9 = "";
        this.loading = false;
        this.saving = false;
        this.tabIndex = 0;
        this.assetIndex = environment.assetIndex;
        this.assetSymbol = environment.assetSymbol;
        this.assetTokenAddress = environment.assetTokenAddress;
        this.pageLimit = 20;
        this.pageOffset = 0;
        this.willShowDeposit = false;
        this.willShowWithdraw = false;
        this.willShowSubscribe = false;
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
        this.paymentAccountAddr = '';
    }
    BuyComponent.prototype.ngOnInit = function () {
        this.load();
    };
    BuyComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userInfo, assetInfo, userSubscription, all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contractService.waitForConnection()];
                    case 1:
                        _a.sent();
                        this.paymentAccountAddr = this.contractService.address;
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getUserInfo(this.contractService.address)];
                                        case 1:
                                            userInfo = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getAssetInfo()];
                                        case 1:
                                            assetInfo = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getSubscriptionByUser(this.contractService.address)];
                                        case 1:
                                            userSubscription = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getPremiumRate(this.contractService.address)];
                                        case 1:
                                            _a.premiumRate = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a, _b;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _a = this;
                                            _b = this.getTokenBalance;
                                            return [4 /*yield*/, this.contractService.getEffectiveCapacity()];
                                        case 1:
                                            _a.effectiveCapacity = _b.apply(this, [(_c.sent()), environment.usdcDecimals]);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.loadRecords()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.loadAddresses()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        this.loading = true;
                        return [4 /*yield*/, Promise.all(all)];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        if (this.tabIndex == 0) {
                            this.predepositBalance = this.getTokenBalance(userInfo[0], environment.usdcDecimals);
                            this.myCurrentCoverage = this.getTokenBalance(userSubscription[0], environment.usdcDecimals);
                            this.myFutureCoverage = this.getTokenBalance(userSubscription[2], environment.usdcDecimals);
                            this.myCurrentPremium = this.getTokenBalance(userInfo[2], environment.usdcDecimals);
                            this.myFuturePremium = this.getTokenBalance(((+userSubscription[2]) * (+this.premiumRate) / 1e6).toFixed(2), environment.usdcDecimals);
                            this.weeksBeCovered = Math.floor((+userInfo[0]) / (+userInfo[2]));
                            this.isCovered = (+userInfo[2]) > 0 && this.weeksBeCovered > 0;
                        }
                        else {
                            this.predepositBalance = this.getTokenBalance(userInfo[1], environment.assetDecimals, environment.assetPrecision);
                            this.myCurrentCoverage = this.getTokenBalance(userSubscription[1], environment.usdcDecimals);
                            this.myFutureCoverage = this.getTokenBalance(userSubscription[3], environment.usdcDecimals);
                            this.myCurrentPremium = this.getTokenBalance((+userInfo[3]) * (+assetInfo.tokenPrice / 1e18), environment.usdcDecimals);
                            this.myFuturePremium = this.getTokenBalance(((+userSubscription[3]) * (+this.premiumRate) / 1e6).toFixed(2), environment.usdcDecimals);
                            this.weeksBeCovered = Math.floor((+userInfo[1]) / (+userInfo[3]));
                            this.isCovered = (+userInfo[3]) > 0 && this.weeksBeCovered > 0;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BuyComponent.prototype.loadRecords = function () {
        return __awaiter(this, void 0, void 0, function () {
            var records;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getRetailHistory(this.assetIndex, this.contractService.address, this.pageLimit, this.pageOffset)];
                    case 1:
                        records = _a.sent();
                        this.records = records.map(function (r) {
                            return {
                                blockTime: r.blockTime,
                                futureBase: _this.getTokenBalance(r.futureBase, environment.usdcDecimals),
                                currentBase: _this.getTokenBalance(r.currentBase, environment.usdcDecimals),
                                premiumBase: _this.getTokenBalance(r.premiumBase, environment.usdcDecimals),
                                futureAsset: _this.getTokenBalance(r.futureAsset, environment.usdcDecimals),
                                currentAsset: _this.getTokenBalance(r.currentAsset, environment.usdcDecimals),
                                premiumAsset: _this.getTokenBalance(r.premiumAsset, environment.assetDecimals, environment.assetPrecision)
                            };
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    BuyComponent.prototype.loadAddresses = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, all, i, i;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = [];
                        all = [];
                        for (i = 0; i < 10; ++i) {
                            all.push((function (i) { return __awaiter(_this, void 0, void 0, function () {
                                var _a, _b, e_1;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _c.trys.push([0, 2, , 3]);
                                            _a = result;
                                            _b = i;
                                            return [4 /*yield*/, this.contractService.getRetailUserMap(i)];
                                        case 1:
                                            _a[_b] = _c.sent();
                                            return [3 /*break*/, 3];
                                        case 2:
                                            e_1 = _c.sent();
                                            return [3 /*break*/, 3];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); })(i));
                        }
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        for (i = 0; i < result.length; ++i) {
                            if (i == 0) {
                                this.address0 = result[0];
                            }
                            else if (i == 1) {
                                this.address1 = result[1];
                            }
                            else if (i == 2) {
                                this.address2 = result[2];
                            }
                            else if (i == 3) {
                                this.address3 = result[3];
                            }
                            else if (i == 4) {
                                this.address4 = result[4];
                            }
                            else if (i == 5) {
                                this.address5 = result[5];
                            }
                            else if (i == 6) {
                                this.address6 = result[6];
                            }
                            else if (i == 7) {
                                this.address7 = result[7];
                            }
                            else if (i == 8) {
                                this.address8 = result[8];
                            }
                            else if (i == 9) {
                                this.address9 = result[9];
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BuyComponent.prototype.refresh = function () {
        this.load();
    };
    BuyComponent.prototype.getTokenBalance = function (value, decimals, precision) {
        if (precision === void 0) { precision = 2; }
        return ((+value) / (Math.pow(10, decimals))).toFixed(precision);
    };
    BuyComponent.prototype.formatTokenBalance = function (value) {
        var arr = value.toString().split(".");
        if (arr[1]) {
            return [arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","), arr[1]].join(".");
        }
        else {
            return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    };
    BuyComponent.prototype.formatDate = function (timestamp) {
        var date = new Date(timestamp * 1000);
        return (date.getMonth() + 1) + " / " + date.getDate() + " / " + date.getFullYear();
    };
    BuyComponent.prototype.formatRate = function (value) {
        if (isNaN(value)) {
            return 'N/A';
        }
        return (value / 10000).toFixed(2) + '%';
    };
    BuyComponent.prototype.getNumber = function (value) {
        return parseFloat(value);
    };
    BuyComponent.prototype.showLockedDesc = function (type) {
        if (type === 'PreDepositBalance') {
            this.showAlert("My Predeposit Balance", "Weekly coverage cost will be automatically deducted from the pre-deposit balance to save user’s gas. Please keep enough balance to maintain your coverage plan.");
        }
        else if (type === 'EstimateCoveragePeriod') {
            this.showAlert("Estimate Coverage Period", "Estimate coverage period is calculated based on your coverage amount and pre-deposit balance. Keep it for few weeks to have a peace of mind.");
        }
    };
    BuyComponent.prototype.toggleShowFloatPopup = function ($event, status) {
        var floatItem = $event.target.parentNode.parentNode.querySelector('.float-item');
        floatItem.style.visibility = status;
    };
    BuyComponent.prototype.showDeposit = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowDeposit = true;
    };
    BuyComponent.prototype.closeDeposit = function () {
        this.willShowDeposit = false;
    };
    BuyComponent.prototype.showWithdraw = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowWithdraw = true;
    };
    BuyComponent.prototype.toggleLocked = function () {
        this.isEditMode = true;
    };
    BuyComponent.prototype.isValidAddress = function (address) {
        return address && address.length == 42 && address[0] == "0" && address[1] == "x";
    };
    BuyComponent.prototype.saveEditResult = function () {
        return __awaiter(this, void 0, void 0, function () {
            var array, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        array = [];
                        if (this.isValidAddress(this.address0)) {
                            array.push(this.address0);
                        }
                        if (this.isValidAddress(this.address1)) {
                            array.push(this.address1);
                        }
                        if (this.isValidAddress(this.address2)) {
                            array.push(this.address2);
                        }
                        if (this.isValidAddress(this.address3)) {
                            array.push(this.address3);
                        }
                        if (this.isValidAddress(this.address4)) {
                            array.push(this.address4);
                        }
                        if (this.isValidAddress(this.address5)) {
                            array.push(this.address5);
                        }
                        if (this.isValidAddress(this.address6)) {
                            array.push(this.address6);
                        }
                        if (this.isValidAddress(this.address7)) {
                            array.push(this.address7);
                        }
                        if (this.isValidAddress(this.address8)) {
                            array.push(this.address8);
                        }
                        if (this.isValidAddress(this.address9)) {
                            array.push(this.address9);
                        }
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        this.transactionsService.updateProcessingTransaction(updateMappingTransaction.type, true);
                        return [4 /*yield*/, this.contractService.updateMapping(array)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadAddresses()];
                    case 3:
                        _a.sent();
                        this.isEditMode = false;
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.transactionsService.updateProcessingTransaction(updateMappingTransaction.type, false);
                        this.saving = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    BuyComponent.prototype.closeWithdraw = function () {
        this.willShowWithdraw = false;
    };
    BuyComponent.prototype.updateProtectList = function () { };
    BuyComponent.prototype.showSubscribe = function (assetIndex) {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowSubscribe = true;
        this.assetSymbol = this.asset.assetSymbol;
    };
    BuyComponent.prototype.closeSubscribe = function () {
        this.willShowSubscribe = false;
    };
    BuyComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    BuyComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    BuyComponent.prototype.showUSDCTab = function () {
        this.tabIndex = 0;
        this.load();
    };
    BuyComponent.prototype.showAssetTab = function (idx) {
        this.tabIndex = idx;
        this.load();
    };
    BuyComponent.prototype.goFirst = function () {
        this.pageOffset = 0;
        this.loadRecords();
    };
    BuyComponent.prototype.goPrev = function () {
        this.pageOffset -= this.pageLimit;
        this.loadRecords();
    };
    BuyComponent.prototype.goNext = function () {
        this.pageOffset -= this.pageLimit;
        this.loadRecords();
    };
    BuyComponent.prototype.hasFirst = function () {
        return this.pageOffset > 0;
    };
    BuyComponent.prototype.hasPrev = function () {
        return this.pageOffset >= this.pageLimit;
    };
    BuyComponent.prototype.hasNext = function () {
        return this.records.length >= this.pageLimit;
    };
    return BuyComponent;
}());
export { BuyComponent };
